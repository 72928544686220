import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import Toast from '../../components/forms/Toast';
import Screen from '../../components/common/Screen';
import Card from '../../components/elements/Card';
import Title from '../../components/common/Title';
import Icon from '../../components/common/Icon';


const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle state for password visibility
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const MIN_PASSWORD_LENGTH = 8;
  const MAX_PASSWORD_LENGTH = 20;
  const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;':",.<>?/\\])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;':",.<>?/\\]{8,20}$/;

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const token = new URLSearchParams(location.search).get('token');

    if (newPassword.length < MIN_PASSWORD_LENGTH || newPassword.length > MAX_PASSWORD_LENGTH) {
      setError(t('passwordLengthError', { min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH }));
      setShowToast(true);
      return;
    }

    if (!PASSWORD_PATTERN.test(newPassword)) {
      setError(t('passwordPatternError'));
      setShowToast(true);
      return;
    }

    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, new_password: newPassword })
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message);
        setShowToast(true);
        setTimeout(() => navigate('/login'), 3000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'somethingWentWrong');
      }
    } catch (error) {
      setError(error.message);
      setShowToast(true);
    }
  };

  return (
    <Screen backgroundImage={require('./../../assets/images/login.png')}>
      <div className="center">
        <form onSubmit={handleResetPassword}>
          <Card style={{ width: '18rem', opacity: '0.9' }}>
            <Title>{t("resetPassword")}</Title>
            <p className='my-4'>{t("passwordPatternError")}</p>
            <div style={{ position: 'relative' }}>
              <InputField
                type={showPassword ? "text" : "password"} // Toggle input type
                name="new_password"
                label={t("newPassword")}
                placeholder={t("enterNewPassword")}
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                minLength={8}
                maxLength={20}
              />
              {/* Eye Icon */}
              <span
                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <Icon name="eye-crossed" theme='secondary' /> : <Icon name="eye" theme='secondary' />}
              </span>
            </div>
            <Button type="submit">{t('resetPassword')}</Button>

            <Toast
              showToast={showToast}
              setShowToast={setShowToast}
              message={successMessage ? t(successMessage) : t(error)}
              icon={successMessage ? "check-circle" : "exclamation-circle"}
            />
          </Card>
        </form>
      </div>
    </Screen>
  );
};

export default ResetPasswordPage;
