import React from 'react';
import { useState, useEffect } from 'react';
import { fetchUserInfo, formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import CoverImage from '../../components/images/CoverImage';
import NavBar from '../../components/common/NavBar';
import Screen from '../../components/common/Screen';
import Button from '../../components/elements/Button';
import Icon from '../../components/common/Icon';
import Image from '../../components/images/Image';
import Title from '../../components/common/Title';
import Center from '../../components/css/Center';
import Base from '../Base';
import { Link } from 'react-router-dom';
import Left from '../../components/css/Left';
import SeeAll from '../../components/common/SeeAll';
import HorizontalScroll from '../../components/elements/HorizontalScroll';

const styles = {
  h3: {
    textAlign: 'left'
  },
};

const Home = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/home', setUserInfo, setLoading, setError, []);
  }, []);

  return (
    <Base>

      
      <div>

          {userInfo?.role === 'player' && 
          <div className='d-flex flex-column justify-content-center align-items-center mt-2 mb-2'>
            <Image to="/team" size='22rem' className='my-1 outline-light' onClick={() => localStorage.setItem('otherTeamId', userInfo.team_id)} url={userInfo?.team_photo_url ? userInfo?.team_photo_url : require('./../../assets/images/fallback/team.png')}>
              <div className="p-2 image-caption">
                <Title heading="5" my="0">{loading ? <p className="placeholder-glow"><span className="placeholder col-8"></span></p> : userInfo.team_name}</Title> 
                {loading ? <p className="placeholder-glow"><span className="placeholder col-6"></span></p> : userInfo.company_name}

              </div>
              <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem'}}>
                  <Icon name="choose" className="m-2" theme="secondary" style={{ fontSize: '2rem'}}  />
              </div>
            </Image>
          </div>
          }

          {userInfo?.role === 'crew' && 
          <Card className='d-flex flex-column justify-content-center align-items-center mt-2'>
            <Title heading="1" my='0' className='underline-secondary mb-2 mt-1' >{t('scoreEntry')} </Title>
            <Image to="/crew-battles" size='22rem' className='my-1' url={userInfo?.activity_photo_url ? userInfo?.activity_photo_url : undefined} >
              <div className="p-2 image-caption">
                <Title heading="6" my="0">{userInfo.activity_name ? userInfo.activity_name : t('noBattleYet')}</Title> 
              </div>
            </Image>
          </Card>
          
          }

          <Card to="/event" className='d-flex flex-row align-items-center justify-content-center' >
              <Image url={userInfo && userInfo.photo_url} className="circle" size='6rem' contain />
              <div className='d-flex flex-column ms-2 text-start'>
                  <Title heading='6' my="0">
                      {loading ? (
                          <p className="placeholder-glow">
                              <span className="placeholder col-6"></span>
                          </p>
                      ) : userInfo.name}
                  </Title>
                      {loading ? (
                          <p className="placeholder-glow">
                              <span className="placeholder col-4"></span>
                          </p>
                      ) : formatDate(userInfo.date)}
              </div>
          </Card>

      

          <Card to='/teams'>
            <SeeAll />

            <Left>
              <Title my='1' className='mb-2'><Icon name="employees" theme="secondary" style={{fontSize: "1.2rem"}}/> {t('teams')}</Title>
            </Left>

            <HorizontalScroll>
              {userInfo?.teams?.map((team) => (
                <div
                  className='d-inline-flex justify-content-center mb-3'
                  style={{ marginRight: '1rem' }}
                  key={team.id}
                >
                  <Link
                    to="/team"
                    onClick={() => localStorage.setItem('otherTeamId', team.id)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Image size="11rem" url={team.photo_url ? team.photo_url : require('./../../assets/images/fallback/team.png')} className='outline-light'>
                      <div className="p-2 image-caption">
                        <Title heading="6" my="0">{team.name}</Title>
                        {team.company_name}
                      </div>
                    </Image>
                  </Link>
                </div>
              ))}
            </HorizontalScroll>
          </Card>

          {!userInfo?.organizer_id && 
            <Card to='/companies'>
              <SeeAll />

              <Left>
                <Title my='1' className='mb-2'><Icon name="building" theme="secondary" style={{fontSize: "1.2rem"}}/> {t('companies')}</Title>
              </Left>

              <HorizontalScroll>
                {userInfo?.companies?.map((company) => (
                  <div
                    className='d-inline-flex justify-content-center mb-3'
                    style={{ marginRight: '1rem' }}
                    key={company.id}
                  >
                    <Link
                      to="/company"
                      onClick={() => localStorage.setItem('otherCompanyId', company.id)}
                      style={{ textDecoration: 'none' }}
                    >
                      <Image size="11rem" url={company.photo_url ? company.photo_url : undefined} className='outline-light' contain>
                        <div className="p-2 image-caption">
                          <Title heading="6" my="0">{company.name}</Title>
                        </div>
                      </Image>
                    </Link>
                  </div>
                ))}
              </HorizontalScroll>

            </Card>
          }

          <Card to='/battles'>
            <SeeAll />

            <Left>
              <Title my='1' className='mb-2'><Icon name="running" theme="secondary" style={{fontSize: "1.2rem"}}/> {t('challenges')}</Title>
            </Left>

            <HorizontalScroll>
              {userInfo?.battles?.map((battle) => (
                <div
                  className='d-inline-flex justify-content-center mb-3'
                  style={{ marginRight: '1rem' }}
                  key={battle.id}
                >
                  <Link
                    to="/battle"
                    onClick={() => localStorage.setItem('selectedBattleId', battle.id)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Image size="11rem" url={battle.photo_url ? battle.photo_url : require('./../../assets/images/fallback/activity.png')} className='outline-light'>
                      <div className="p-2 image-caption">
                        <Title heading="6" my="0">{battle.name}</Title>
                      </div>
                    </Image>
                  </Link>
                </div>
              ))}
            </HorizontalScroll>

          </Card>





      <div className='row px-3 mb-3 justify-content-center g-2'>
        
        

      {userInfo?.role === 'player' && 
        <div className="col-3 text-center">
          <Button theme="light" mb="0" p="3" to="/planning">
            <div className="d-flex flex-column align-items-center">
              <Icon name="calendar-lines" theme="secondary" />
              <label className="mt-2 fs-7">{t('planning')}</label>
            </div>
          </Button>
        </div>
      }

      {userInfo?.role === 'player' && 
        <div className="col-3 text-center">
          <Button theme="light" mb="0" p="3" to="/scores">
            <div className="d-flex flex-column align-items-center">
              <Icon name="hundred-points" theme="secondary" />
              <label className="mt-2 fs-7">{t('scores')}</label>
            </div>
          </Button>
        </div>
      }

      <div className="col-3 text-center">
        <Button theme="light" mb="0" p="3" to="/leaderboard">
          <div className="d-flex flex-column align-items-center">
            <Icon name="ranking-star" theme="secondary" />
            <label className="mt-2 fs-7">{t('leaderboard')}</label>
          </div>
        </Button>
      </div>


          

          {/* <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/houses">
              <Icon name="house-flag" />
            </Button>
            <label className='my-2'>{t('houses')} </label>
          </div> */}
          {/* <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/faq">
              <Icon name="question" />
            </Button>
            <label className='my-2'>{t('faq')}</label>
          </div>
          <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/map">
              <Icon name="map" />
            </Button>
            <label className='my-2'>{t('map')}</label>
          </div>
          <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/rules">
              <Icon name="megaphone" />
            </Button>
            <label className='my-2'>{t('rules')}</label>
          </div> */}
          <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/awards">
              <div className="d-flex flex-column align-items-center">
                <Icon name="medal" theme='secondary' />
                <label className='mt-2 fs-7'>{t('awards')}</label>
              </div>
            </Button>
          </div>
      </div>
      </div>



    </Base>
  );
};

export default Home;