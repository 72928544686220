import React from 'react';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Image from '../../components/images/Image';
import Card from '../../components/elements/Card';
import Left from '../../components/css/Left';
import { Link } from 'react-router-dom';
import Back from '../../components/common/Back';
import Icon from '../../components/common/Icon';
import InputField from '../../components/forms/InputField';
import Center from '../../components/css/Center';

const Teams = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/teams', setUserInfo, setLoading, setError, ["selectedEventId"]);
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase()); // Convert to lowercase for case-insensitive matching
  };

  // Filter the teams based on the search query
  const filteredTeams = userInfo?.teams?.filter((team) =>
    team.name.toLowerCase().includes(searchQuery) || 
    team.company_name.toLowerCase().includes(searchQuery)
  );

  return (
    <Base activeNav="home">
      
        <Card>
        <Back to='/home' />
          <Title my='1' className='mt-3'>{t('teams')}</Title>
          <div className='mb-3'>
            {userInfo && <>
            <Icon name="employees" className="mx-1" theme='secondary' />
            {userInfo.teams.length} 
            </>}
            
            </div>
        </Card>
        
        <div className='px-3 row align-items-center'>
          <div className='col-1' style={{ marginTop: '-0.7rem' }}>
              <Icon name="search" />
          </div>

          <div className='col-11'>
          <InputField placeholder={t('searchTeams')} 
          name='searchTeams'
          value={searchQuery} // Bind the input value to the state
          onChange={handleSearchChange} // Update state on input change
          >
              <Icon name="employees" />
          </InputField>
          </div>
        </div>


        {filteredTeams && filteredTeams.length > 0 ? (
        <div className='row d-flex justify-content-center g-0'>
          {filteredTeams.map((team) => (
            <div className='col d-flex justify-content-center mb-3' key={team.id}>
              <Link
                to="/team"
                onClick={() => localStorage.setItem('otherTeamId', team.id)}
                style={{ textDecoration: 'none' }}
              >
                <Image size="11rem" url={team.photo_url ? team.photo_url : require('./../../assets/images/fallback/team.png')} className='outline-light'>
                  <div className="p-2 image-caption">
                    <Title heading="6" my="0">{team.name}</Title> 
                    {team.company_name}
                  </div>
                </Image>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <Card className="mt-1" p='3'>
          <Center><Image url={require('./../../assets/images/not-found.png')} className='my-4' /></Center>
          <Title heading='6' my="0">{t('noTeamsFound')}</Title>
        </Card>
      )}

    </Base>
  );
};

export default Teams;