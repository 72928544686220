import React, { useEffect, useState } from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Button from '../../components/elements/Button';
import { useTranslation } from 'react-i18next';
import { fetchUserInfo, formatDate } from '../../utils';
import { logout } from '../../utils';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/team-select', setUserInfo, setLoading, setError);
  }, []);


  const handleTeamSelect = async (teamId, eventId) => {
    try {
      // Send POST request with the selected team_id
      const response = await fetch('/api/team-select', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify({ team_id: teamId, event_id: eventId }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to select team');
      }
  
      const data = await response.json();
      console.log('Team selected:', data);
  
      // Store the selected team and event in local storage
      localStorage.setItem('otherTeamId', teamId);  // Assuming this is needed
  
      // Navigate to the home page or perform other actions
      window.location.href = '/home';  // Example navigation
    } catch (error) {
      console.error('Error selecting team:', error);
    }
  };

  return (
    <Screen>
      <div className='top'>

        


        <Card>

          <Back to="/role" />
          <Title my="4">{t('selectYourTeam')}</Title>

          <Button 
              theme="outline-secondary" 
              p="2" 
              to="/login" 
              styles={{ position: 'absolute', right: '1.3rem', top: '1.7rem', backgroundColor: 'inherit' }} 
              onClick={logout}
          >
              <Icon name="sign-out-alt" theme='secondary' />
          </Button>
        </Card>

        {userInfo && userInfo.teams && (
          <div>
            {userInfo.teams.map((team) => (
              <Card key={team.id} to="/home" onClick={() => handleTeamSelect(team.team_id, team.event_id)}>
                <div className='justify-content-between d-flex align-items-center'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <Image size="5rem" className='me-3' url={team.team_photo ? team.team_photo : require('./../../assets/images/fallback/team.png')} />
                    </div>
                    <div className='d-flex flex-column text-start'>
                      <div><b>{team.team_name} </b></div>
                      <div className='text-secondary'>{team.event_name} </div>
                      <div className='text-secondary'>{formatDate(team.event_date)} </div>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
    </Screen>
  );
};

export default Page;