import React from 'react';
import Icon from './Icon';
import { useTranslation } from 'react-i18next'; // Replace with your i18n setup if applicable

const SeeAll = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div
              style={{
                textDecoration: 'none',
                color: 'inherit',
                position: 'absolute',
                right: '1rem',
                top: '0.5rem',
              }}
            >
        <p className='text-secondary'>
        {t('seeAll')} <Icon name='angle-right' style={{ fontSize: '0.7rem' }} className='text-secondary' />
        </p>
    </div>
  );
};

export default SeeAll;
