import React from 'react';

const HorizontalScroll = ({ children }) => {
  return (
    <div
      className='d-flex justify-content-start'
      style={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '0',
      }}
    >
      {children}
    </div>
  );
};

export default HorizontalScroll;
