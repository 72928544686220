import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo, formatDate } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/score', setUserInfo, setLoading, setError, ["selectedScoreId"]);
  }, []);

  return (
    <Base activeNav="home">

        <Card>
            <Back to="/scores" />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image url={userInfo?.teams[0].activity_photo ? userInfo?.teams[0].activity_photo : require('./../../assets/images/fallback/activity.png')} className={'circle'} />
            </div>

            <Title my='1' className={'mt-3'}>{userInfo && userInfo.teams[0].activity_name}</Title>

            <p className='text-secondary'>{userInfo && userInfo.teams[0].start_time} - {userInfo && userInfo.teams[0].end_time}</p>

            
            
        </Card>

        {userInfo && userInfo.teams.map((team, index) => (
          <Card key={team.id} to="/team" onClick={() => localStorage.setItem('otherTeamId', team.team_id)} className={`${team.team_id === userInfo.user_team_id ? 'outline-secondary' : ''}`}>
            <div className='justify-content-between d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <div>
                  <Image size="4rem" className='me-2' url={team.team_photo? team.team_photo : require('./../../assets/images/fallback/team.png')} />
                </div>
                <div className='d-flex flex-column text-start'>
                    <div><b>{team.name}</b></div>
                    <div>{team.company_name}</div>

                    {/* <div className='text-secondary'>
                        {team.score_1 !== undefined && team.score_1 !== 0 && <>{team.score_1} {t('points')}</>}
                        {team.score_2 !== undefined && team.score_2 !== 0 && <>{team.score_2} {t('points')}</>}
                        {team.time_1 !== undefined && team.time_1 !== 0 && <>{team.time_1}</>}
                        {team.time_2 !== undefined && team.time_2 !== 0 && <>{team.time_2}</>}
                        
                    </div> */}

                    <div className='d-flex'>
                        
                        {team.rank > 9 ? (
                          <>
                            <Icon name={`circle-${Math.floor(team.rank / 10)}`} theme='secondary' className='me-1' />
                            <Icon name={`circle-${team.rank % 10}`} theme='secondary' className='me-1' />
                          </>
                        ) : (
                          <Icon name={`circle-${team.rank}`} theme='secondary' className='me-1' />
                        )}

                        {team.joker_id && team.joker_id === team.activity_id && (
                            <Icon name="star" theme='secondary' className='me-1' />
                        )}
                        {team.is_fairplay}
                        {team.is_fairplay === 1 && (
                            <Icon name='laugh-beam' style={{ color: '#45b6fe' }} className='me-1' />
                        )}
                        {team.is_ladies === 1 && (
                            <Icon name='woman-head' style={{ color: '#FF6ec7' }} className='me-1' />
                        )}
                        {team.is_disqualified === 1 && (
                            <Icon name='user-xmark' className='me-1 text-danger' />
                        )}

                    </div>
                </div>
              </div>
              <div className='me-2 underline-secondary' style={{ fontSize:'16px' }}>
                <b>+ {team.points}</b>
              </div>
            </div>
          </Card>
        ))}


   

    <Card>
      <Title my='0' heading='6' className='mb-2'>{t('legend')}</Title>
      <div>
        <Icon name='circle-1' theme='secondary' className='me-1' /> = {t('position')}
      </div>

      <div>
        <Icon name='star' theme='secondary' className='me-1' /> = {t('joker')}
      </div>
      <div>
        <Icon name='woman-head' style={{ color: '#FF6ec7' }} className='me-1' /> = {t('ladies')}
      </div>
      <div>
        <Icon name='laugh-beam' style={{ color: '#45b6fe' }} className='me-1' /> = {t('fairplay')}
      </div>
      <div>
        <Icon name='user-xmark' className='me-1 text-danger' /> = {t('disqualified')}
      </div>
    </Card>

    </Base>
  );
};

export default Page;