import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/scores', setUserInfo, setLoading, setError, []);
  }, []);

  return (
    <Base activeNav="home">
        <Card>
            <Back to="/home" />
            <Title>
                <Icon name='hundred-points' className='me-3' style={{fontSize:'24px'}} theme='secondary' />
                {t('scores')}
            </Title>
        </Card>
        

        {userInfo && userInfo.scores.map((score, index) => (
          <Card key={score.id} to='/score' onClick={() => localStorage.setItem('selectedScoreId', score.id)} >
            <div className='justify-content-between d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                    <div>
                        <Image size="4rem" className='me-2' url={score.photo_url? score.photo_url: require('./../../assets/images/fallback/activity.png')} />
                    </div>
                    <div className='d-flex flex-column text-start' style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        <div>{score.name}</div>
                          <div className='d-flex'>
                            {score.joker !== undefined && score.joker !== 0 && (
                                <Icon name="star" theme='secondary' className='me-1' />
                            )}
                            {score.ladies !== undefined && score.ladies !== 0 && (
                                <Icon name='woman-head' style={{ color: '#FF6ec7' }} className='me-1' />
                            )}
                        </div>
                    </div>
                </div>

                <div className='me-2 underline-secondary' style={{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                  + {score.score + score.ladies + score.joker}
                </div>
            </div>
          </Card>
        ))}


    </Base>
  );
};

export default Page;